<template>
  <div class="component-wrap" v-if="!showReview">
    <div class="component-tit">
      {{ $t("mine.homework_approval") }}
      <!-- 作业批阅 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.detailName"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch(queryParam)"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="table-wrap">
      <a-table
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="indexId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #tooltip="{ text }">
          <a-tooltip placement="topLeft">
            <template #title>{{ text }}</template>
            <span>{{ text }}</span>
          </a-tooltip>
        </template>
        <template #source="{ text }">
          <a-tooltip placement="topLeft" :title="text">
            {{ text }}
          </a-tooltip>
        </template>
        <template #status="{ text }">
          <span style="color: #44b44f" v-if="text == 1">{{
            $t("CM_Completed")
          }}</span>
          <!-- 已完成 -->
          <span style="color: #e8673e" v-else-if="text == 2">{{
            $t("CM_NotCompleted")
          }}</span>
          <!-- 未完成 -->
        </template>
        <template #waits="{ record }">
          {{ record.waits }}/{{ record.totals }}
        </template>
        <template #action="{ record }">
          <!-- 评估表批阅 -->
          <a-button
            type="link"
            @click="handleReview(record)"
          >
            {{ $t('cm_view') }}
            <!-- 查看 -->
          </a-button>
        </template>
      </a-table>
    </div>
  </div>
  <review :detail="currentInfo" @back="handleBack" v-else />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useRoute } from "vue-router";
import { homeworkMakingList } from "@/api/homework";
import { useFetchData } from "@/utils/useFetchData";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import { getTaskType } from "@/utils/business";
import review from "./homework/review.vue";
export default {
  components: {
    ListFilter,
    review,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const stateData = reactive({
      reviewStatus: [
        { id: 1, name: $t("CM_Completed") }, // 已完成
        { id: 2, name: $t("CM_NotCompleted") }, // 待完成
      ],
      columns: [
        {
          title: $t("homework.table.name"),
          // title: "作业名称",
          dataIndex: "detailName",
          ellipsis: true,
          width: 200,
          slots: { customRender: "tooltip" },
        },
        {
          title: $t("homework.table.score"),
          // title: "作业来源",
          dataIndex: "source",
          ellipsis: true,
          width: 200,
          slots: { customRender: "source" },
        },
        {
          title: $t("homework.table.wait"),
          // title: "未批阅/总批阅",
          dataIndex: "waits",
          ellipsis: true,
          width: 250,
          slots: { customRender: "waits" },
        },
        {
          title: $t("homework.table.state"),
          // title: "批阅状态",
          dataIndex: "status",
          width: 100,
          slots: { customRender: "status" },
        },
        {
          title: $t("homework.table.operate"),
          // title: "操作",
          dataIndex: "action",
          fixed: "right",
          align: 'center',
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      showReview: route.query.taskId ? true : false,
      currentInfo: {
        taskId: Number(route.query.taskId || 0),
        detailId: Number(route.query.detailId || 0),
      },
    });

    const generateSource = (record) => {
      let str = '';
      if (record.courseId && record.taskId) {
        // 任务-课程-作业 
        str = `【${getTaskType(record.taskType)}】${record.taskName}-${record.courseName}`;    
      } else if (record.courseId) {
        // 课程-作业
        str = `【${$t("course.online_course")}】${record.courseName}`
      } else {
        // 任务-作业
        str = `【${getTaskType(record.taskType)}】 ${record.taskName}`
      }
      return str;
    };
    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return homeworkMakingList(form).then((res) => {
        if (res.data.list) {
          res.data.list.forEach((item, index) => {
            item.indexId = index + 1;
            item.source = generateSource(item);
          });
        }
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({});

    const handleSearch = (searchModel) => {
      if (searchModel) {
        queryParam = searchModel;
      } else {
        queryParam.detailName = "";
        delete queryParam.status;
      }
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const switchStatus = (arr) => {
      queryParam.status = arr[0];
      handleSearch(queryParam);
    };

    const handleReview = (item) => {
      stateData.currentInfo = item;
      stateData.showReview = true;
    };

    const handleBack = () => {
      handleSearch();
      stateData.showReview = false;
    };
    return {
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      switchStatus,
      handleSearch,
      getTaskType,
      handleReview,
      handleBack,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;
    border-bottom: 1px solid #f4f4f4;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
</style>
